import React, {
  ChangeEvent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { DirectionsCar } from '@material-ui/icons';
import {
  Container,
  Box,
  Button,
  Dialog,
  DialogActions,
  Typography,
  useMediaQuery,
  useTheme,
  Card,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import images from 'src/assets/payment';
import { Layout } from 'src/layouts';
import { confirmPayPal, updateCard } from 'src/api/quote';
import { QuoteContext } from 'src/views/Quote/QuoteContext';
import { Image } from 'src/components/atoms';
import {
  CardOnFile,
  IAppointment,
  QuoteShowModal,
  RequestAuthPayPalRepair,
  ResponseAppointment,
} from 'src/types';
import callApi from 'src/api/request';
import { IReduxState } from 'src/store/reducers';
import { cardTypes } from 'src/utils/data';
import useStyles from './styles';
import useFontStyles from '../../fontStyles';
import CheckoutForm from '../ModalFinishBooking/CheckoutForm';

interface ModalHandleEstimateResponseProps {
  show: boolean;
  onClose: () => void;
  payVerified: boolean | undefined;
}

const ModalHandleEstimateResponse = (
  props: ModalHandleEstimateResponseProps
): ReactElement => {
  const { show, onClose, payVerified } = props;
  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();
  const fontClasses = useFontStyles();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [paymentVerified, setPaymentVerified] = useState(false);
  const [payPalTotal, setPayPalTotal] = useState(0);
  const [payWithCard, setPayWithCard] = useState<boolean>(false);
  const [errors, setErrors] = useState<string | undefined | null>(null);
  const [stripeRequestInProgress, setStripeRequestInProgress] = useState(false);
  const [payPalError, setPayPalError] = useState<string | undefined>();
  const [isCardOnFile, setIsCardOnFile] = useState<boolean>(false);
  const [lastFour, setLastFour] = useState<string>('');
  const [cardBrand, setCardBrand] = useState<string>('');
  const [prevCardSelected, setPrevCardSelected] = useState(false);
  const [errorCardOnFile, setErrorCardOnFile] = useState<
    string | undefined | null
  >(null);

  const appointment: IAppointment | null = useSelector(
    (state: IReduxState) => state.quote.appointment
  );
  const { handleShowModal, handleRespondAppointmentEstimate } =
    useContext(QuoteContext);

  const handleCheckboxChange = () => {
    setPrevCardSelected(!prevCardSelected);
  };

  useEffect(() => {
    const userId = appointment?.attributes.user_id;

    if (userId) {
      callApi<CardOnFile>({
        url: `/api/v2/user/card_on_file/${userId}`,
        method: 'GET',
      }).then((response) => {
        if (response.errors && response.errors.length > 0) {
          setErrorCardOnFile(
            'An error occurred while processing your request.'
          );
        } else if (
          response.card_on_file?.card_brand &&
          response.card_on_file?.last_4_digits
        ) {
          setIsCardOnFile(true);
          setLastFour(response.card_on_file?.last_4_digits as string);
          setCardBrand(response.card_on_file?.card_brand);
        }
      });
    }
  }, [appointment?.attributes.user_id]);

  useEffect(() => {
    if (appointment) {
      const fleet = appointment.attributes.fleet_job;

      if (!fleet) {
        if (appointment?.attributes?.member_job === true) {
          setPayPalTotal(
            appointment?.attributes.estimate?.total_member_price || 0
          );
        } else {
          setPayPalTotal(appointment?.attributes.estimate?.total_price || 0);
        }
      }
    }

    if (payVerified) {
      setPaymentVerified(true);
      handleShowModal(QuoteShowModal.SCHEDULE_SERVICE);
    }
  }, [appointment, handleShowModal, payVerified]);

  const classes = useStyles();

  if (!appointment || !appointment?.attributes || !appointment?.id) {
    return <></>;
  }

  const { car } = appointment.attributes;

  const handlePayPalCheckout = async (
    order_id: string,
    auth_id: string,
    payer_id: string
  ) => {
    const data: RequestAuthPayPalRepair = {
      paypal_repair_order_id: order_id,
      paypal_repair_auth_id: auth_id,
      paypal_payer_id: payer_id,
    };

    confirmPayPal(appointment.id, true, data)
      .then((resp: ResponseAppointment) => {
        if (resp.errors) {
          setErrors(resp.errors.join('. '));
        } else {
          setPaymentVerified(true);
          handleShowModal(QuoteShowModal.SCHEDULE_SERVICE);
        }
      })
      .catch(() => {
        setErrors('Error checking out with PayPal. Please try again.');
      });
  };

  const handleCheckOut = async () => {
    setStripeRequestInProgress(true);
    setErrors(null);

    if (!stripe || !elements) {
      setStripeRequestInProgress(false);
      return false;
    }

    const cardElem = elements.getElement(CardElement);

    if (!cardElem) {
      setStripeRequestInProgress(false);
      return false;
    }

    const { token } = await stripe.createToken(cardElem);

    if (token) {
      updateCard(appointment.id, token.id)
        .then((resp: ResponseAppointment) => {
          if (resp.errors) {
            setErrors(resp.errors.join('. '));
          } else {
            setPaymentVerified(true);
            handleShowModal(QuoteShowModal.SCHEDULE_SERVICE);
          }
        })
        .finally(() => setStripeRequestInProgress(false));
    } else {
      setStripeRequestInProgress(false);
      setErrors('Error updating Credit Card');
    }

    return true;
  };

  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      scroll="body"
      fullScreen
    >
      <Layout>
        <Container
          maxWidth="lg"
          className={classes.root}
          style={{
            background: '#ffffff',
            display: 'flex',
            flexDirection: isSm ? 'column' : 'row',
          }}
        >
          <div>
            <Card
              style={{
                flex: 1,
                minWidth: !isSm ? 700 : 300,
                padding: isSm ? '20px' : '30px 60px',
                background: '#FFF',
                boxShadow: isSm
                  ? 'none'
                  : 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;',

                borderRadius: 12,
                marginTop: 50,
              }}
              elevation={4}
            >
              <Box
                key="image-payments"
                display="flex"
                flexDirection="row"
                alignItems="center"
                gridGap={10}
                marginTop={2}
                style={{ marginBottom: 30 }}
              >
                {cardTypes.map((ct) => (
                  <Image key={ct.title} src={ct.image} />
                ))}
              </Box>
              <Typography
                style={{
                  fontSize: isSm ? 15 : 30,
                  fontFamily: 'Inter',
                  fontWeight: 700,
                }}
              >
                {paymentVerified ? 'Schedule Appointment' : 'Choose Payment'}
              </Typography>

              <Box style={{ marginTop: '20px' }}>
                <Box style={{ margin: '20px auto' }}>
                  {appointment.attributes?.payment_type === 'stripe' &&
                  appointment.attributes?.credit_card_present ? (
                    <Box style={{ textAlign: 'center', marginTop: '5px' }}>
                      {isCardOnFile && (
                        <Card className={classes.cardOnFile} elevation={0}>
                          <Typography
                            style={{ fontFamily: 'Inter', fontWeight: 600 }}
                          >
                            Use card on file
                          </Typography>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={prevCardSelected}
                                onChange={() => {
                                  setPaymentVerified(true);
                                  handleCheckboxChange();
                                }}
                                color="primary"
                              />
                            }
                            label={`${cardBrand} ending ${lastFour}`}
                          />
                        </Card>
                      )}

                      {prevCardSelected ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          className={classes.reservationButton}
                          onClick={() => {
                            setPaymentVerified(true);
                            handleShowModal(QuoteShowModal.SCHEDULE_SERVICE);
                          }}
                          disabled={false}
                        >
                          Pay with card on file
                          <Image src={images.arrowRight} />
                        </Button>
                      ) : (
                        <>
                          <CheckoutForm errors={errors} />

                          <DialogActions className={classes.actionContainer}>
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              className={classes.reservationButton}
                              onClick={handleCheckOut}
                              disabled={stripeRequestInProgress}
                            >
                              Pay with card
                              <Image src={images.arrowRight} />
                            </Button>
                          </DialogActions>
                        </>
                      )}
                    </Box>
                  ) : (
                    <Box style={{ textAlign: 'center', marginTop: '5px' }}>
                      <>
                        <CheckoutForm errors={errors} />

                        <DialogActions className={classes.actionContainer}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.reservationButton}
                            onClick={handleCheckOut}
                            disabled={stripeRequestInProgress}
                          >
                            Pay with card
                            <Image src={images.arrowRight} />
                          </Button>
                          {errors && (
                            <Typography color="error">{errors}</Typography>
                          )}
                        </DialogActions>
                      </>
                    </Box>
                  )}

                  {payPalTotal > 0 && !payWithCard ? (
                    <>
                      <PayPalScriptProvider
                        options={{
                          clientId:
                            process.env.REACT_APP_PAYPAL_CLIENT_ID || '',
                          intent: 'authorize',
                          'disable-funding': 'card',
                          'enable-funding': 'paylater',
                        }}
                      >
                        <div>
                          <Image src={images.paypal} />
                          <p
                            style={{
                              fontFamily: 'Inter',
                            }}
                          >
                            Pay in interest-free installments
                          </p>
                        </div>
                        <PayPalButtons
                          style={{ layout: 'vertical', color: 'white' }}
                          createOrder={(data, actions) => {
                            return actions.order.create({
                              intent: 'AUTHORIZE',
                              purchase_units: [
                                {
                                  amount: {
                                    value: payPalTotal.toString(),
                                    currency_code: 'USD',
                                  },
                                },
                              ],
                            });
                          }}
                          onApprove={(data, actions) => {
                            if (actions.order) {
                              return actions.order
                                .authorize()
                                .then((details) => {
                                  const orderId = details.id;
                                  const payerId = details?.payer?.payer_id;
                                  let authorizationId: string | undefined;

                                  if (
                                    details?.purchase_units &&
                                    details.purchase_units[0] &&
                                    details.purchase_units[0].payments
                                      ?.authorizations
                                  ) {
                                    authorizationId =
                                      details.purchase_units[0].payments
                                        .authorizations[0].id;
                                  }

                                  if (orderId && authorizationId && payerId) {
                                    handlePayPalCheckout(
                                      orderId,
                                      authorizationId,
                                      payerId
                                    );
                                  }
                                });
                            }

                            return Promise.resolve();
                          }}
                          onError={() =>
                            setPayPalError(
                              'Error checking out with PayPal, please try again.'
                            )
                          }
                        />
                      </PayPalScriptProvider>
                      {payPalError && (
                        <Typography
                          color="error"
                          style={{ margin: '5px 0', textAlign: 'center' }}
                        >
                          {payPalError}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
            </Card>
          </div>
          <div>
            <Card
              style={{
                padding: 25,
                marginTop: 50,
                background: '#FFF',
                boxShadow: isSm
                  ? 'none'
                  : 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;',
                borderRadius: 12,
              }}
              elevation={3}
            >
              <Typography
                style={{ fontSize: 20, fontFamily: 'Inter', fontWeight: 700 }}
              >
                Summary
              </Typography>

              <div
                style={{
                  display: 'flex',
                  gap: 15,
                  marginTop: 20,
                  alignItems: 'flex-start',
                }}
              >
                <DirectionsCar style={{ color: '#005959' }} />

                <Typography
                  style={{ fontSize: 15, fontFamily: 'Inter', fontWeight: 500 }}
                >
                  {' '}
                  {car.year} {car.make}
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: 15,
                  marginTop: 20,
                  alignItems: 'flex-start',
                }}
              >
                <Image src={images.coin} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography className={classes.thickInter}>
                    {' '}
                    {appointment.attributes?.member_job ? appointment?.attributes?.estimate?.total_member_price : appointment?.attributes?.estimate?.total_price}
                  </Typography>
                  <Typography className={classes.thinInter}>
                    {' '}
                    to be charged at time of service
                  </Typography>
                </div>
              </div>
              <p
                className={fontClasses.p1}
                style={{ color: '#474747', marginTop: 30 }}
              >
                <span className={fontClasses.h3} style={{ marginRight: '4px' }}>
                  🎖
                </span>{' '}
                24 month / 24,000 Mile Service Warranty
              </p>
            </Card>
          </div>
        </Container>
      </Layout>
    </Dialog>
  );
};

export default ModalHandleEstimateResponse;
